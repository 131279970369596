import { PanelPlugin } from '@grafana/data';
import { SimplePanel } from './components/SimplePanel';
import { TeamsCXCallIcons } from 'TeamcxIcon';
import { CallPanelOptions } from 'types';

export const plugin = new PanelPlugin<CallPanelOptions>(SimplePanel).setPanelOptions((builder) => {
  return builder
    .addFieldNamePicker({
      path: 'currentValueField',
      name: 'Value Field',
      defaultValue: '',
    })
    .addUnitPicker({
      path: 'unit',
      name: 'Unit',
      defaultValue: '',
    })
    .addNumberInput({
      path: 'currentValueDecimals',
      name: 'Nr. of decimals for value',
      description: 'For the value number',
      defaultValue: undefined,
    })
    .addTextInput({
      path: 'referenceTextFontSize',
      name: 'Font size for reference text',
      description: 'Either in percentage or pixel',
      defaultValue: '12px',
    })
    .addColorPicker({
      path: 'referenceTextColor',
      name: 'Color for reference text',
      description: 'Choose color for text',
      defaultValue: '',
    })
    .addSelect({
      path: 'icon',
      name: 'Icon',           
      settings: {
        options: [          
          {
            value: TeamsCXCallIcons.Total,
            label: 'Total',   
          },                      
          {
            value: TeamsCXCallIcons.AnsweredIn,
            label: 'Answered In',   
          }, 
          {
            value: TeamsCXCallIcons.Abandoned,
            label: 'Abandoned',
          },  
          {
            value: TeamsCXCallIcons.Missed,
            label: 'Missed',   
          },
          {
            value: TeamsCXCallIcons.Answered,
            label: 'Answered',   
          },
          {
            value: TeamsCXCallIcons.Handle,
            label: 'Handle',   
          },
          {
            value: TeamsCXCallIcons.Waiting,
            label: 'Waiting',   
          },
          {
            value: TeamsCXCallIcons.Longest,
            label: 'Longest',   
          },
          {
            value: TeamsCXCallIcons.Outbound,
            label: 'Outbound',   
          },
          {
            value: TeamsCXCallIcons.Inbound,
            label: 'Inbound',   
          },
          {
            value: TeamsCXCallIcons.In60s,
            label: 'In60s',   
          },
        ],
      }
    })
    .addTextInput({
      path: 'iconSize',
      name: 'Icon size',
      description: 'em, px,...',
      defaultValue: '1em',
    })
    ;
});
