import React, { PropsWithChildren } from 'react';
import { PanelProps, getValueFormat, formattedValueToString, ThemeVisualizationColors } from '@grafana/data';
import { CallPanelOptions } from 'types';
import { css, cx } from '@emotion/css';
import { useStyles2, useTheme2 } from '@grafana/ui';
import { GetIcon } from 'TeamcxIcon';

interface Props extends PanelProps<CallPanelOptions> {}

const BASE_FONT_SIZE = 38;

function GetIconStyle(options: CallPanelOptions) {
  return {
    marginRight: '10px',
    verticalAlign: 'middle',
    height: options.iconSize === undefined ? '1em' : options.iconSize,
  };
}

interface TrendDisplay {
  color?: string;
  currentValueFormatted: string;
}

function prepareTrendDisplay(
  options: CallPanelOptions,
  colors: ThemeVisualizationColors,
  currentValueSum: number
): TrendDisplay {
  const currentValueFormat = getValueFormat(options.unit)(
    currentValueSum,
    options.currentValueDecimals,
    undefined,
    undefined
  );

  const currentValueFormatted = formattedValueToString(currentValueFormat);

  const positiveTrendColor = (options.referenceTextColor === undefined ? true : options.referenceTextColor)
    ? colors.getColorByName('green')
    : options.referenceTextColor;

  return {
    color: positiveTrendColor,
    currentValueFormatted: currentValueFormatted,
  };
}

function SpanValue({
  className,
  fontSize,
  color,
  lineHeight,
  display,
  verticalAlign,
  children,
}: PropsWithChildren<{
  className: string;
  fontSize?: string;
  color?: string;
  lineHeight?: string;
  display?: string;
  verticalAlign?: string;
}>) {
  let verticalAlignProp = 'baseline';
  let displayProp = 'block';
  if (display) {
    displayProp = display;
  }
  if (verticalAlign) {
    verticalAlignProp = verticalAlign;
  }
  return (
    <span
      className={className}
      style={{
        display: displayProp,
        fontSize: fontSize,
        color: color,
        lineHeight: lineHeight,
        whiteSpace: 'nowrap',
        verticalAlign: verticalAlignProp,
      }}
    >
      {children}
    </span>
  );
}

export const SimplePanel: React.FC<Props> = ({ options, data, width, height }) => {
  const styles = useStyles2(getPanelStyles);
  const theme = useTheme2();

  const referenceTextFontSize = options.referenceTextFontSize.includes('px')
    ? options.referenceTextFontSize
    : (parseInt(options.referenceTextFontSize, 10) / 100) * BASE_FONT_SIZE + 'px';

  const currentValueSerie = data.series.find((serie) =>
    serie.fields.find((field) => field.name === options.currentValueField)
  );

  const currentValueField = currentValueSerie?.fields.find((field) => field.name === options.currentValueField);

  if (!currentValueField) {
    return (
      <div
        className={cx(
          styles.wrapper,
          css`
            width: ${width}px;
            height: ${height}px;
          `
        )}
      >
        <div className={styles.textBox} style={{ textAlign: 'center' }}>
          <div style={{ marginBottom: '10px', whiteSpace: 'nowrap' }}>
            <SpanValue className="percenttrend-panel-base" lineHeight="1em" display="inline" verticalAlign="middle">
              {GetIcon(options.icon, GetIconStyle(options))}
            </SpanValue>
            <SpanValue
              color={options.referenceTextColor ? options.referenceTextColor : 'black'}
              className="percenttrend-panel-base"
              fontSize={referenceTextFontSize}
              lineHeight="1em"
              display="inline"
              verticalAlign="middle"
            >
              0
            </SpanValue>
          </div>
        </div>
      </div>
    );
  }
  if (currentValueField.values.length === 0) {
    return <p>Selected fields are empty</p>;
  }
  const currentValueSum = currentValueField.values.toArray().reduce((sum, current) => sum + current, 0);

  const display = prepareTrendDisplay(options, theme.visualization, currentValueSum);

  return (
    <div
      className={cx(
        styles.wrapper,
        css`
          width: ${width}px;
          height: ${height}px;
        `
      )}
    >
      <div className={styles.textBox} style={{ textAlign: 'center' }}>
        <div style={{ marginBottom: '10px', whiteSpace: 'nowrap' }}>
          <SpanValue className="percenttrend-panel-base" lineHeight="1em" display="inline" verticalAlign="middle">
            {GetIcon(options.icon, GetIconStyle(options))}
          </SpanValue>
          <SpanValue
            color={options.referenceTextColor ? options.referenceTextColor : 'black'}
            className="percenttrend-panel-base"
            fontSize={referenceTextFontSize}
            lineHeight="1em"
            display="inline"
            verticalAlign="middle"
          >
            {display.currentValueFormatted}
          </SpanValue>
        </div>
      </div>
    </div>
  );
};

function getPanelStyles() {
  return {
    wrapper: css`
      position: relative;
    `,
    svg: css`
      position: absolute;
      top: 0;
      left: 0;
    `,
    textBox: css`
      padding: 10px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
    `,
  };
}
